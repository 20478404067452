import admin from "./admin"
import newhires from "./newhires"
import accounts from "./accounts"
import { combineReducers } from "@reduxjs/toolkit"

const rootReducer = combineReducers({
  admin,
  newhires,
  accounts,
})

export default rootReducer

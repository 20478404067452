import {
    Box,
    Stepper,
    Step,
    StepLabel,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { INewHireDetails } from "../../interfaces"


interface IProps {
    newhire: INewHireDetails
}

const newHireProgressIndicators: string[] = [
    'isEmailSent',
    'isFollowupEmailSent',
    'isVerified',
    'isAssetValidated',
    'isPasswordDisplayed'
]

const NewHiresProgress = ({ newhire }: IProps) => {
    const [activeStepsCount, setActiveStepsCount] = useState<number>(0)

    useEffect(() => {
        // count active(complete) steps
        for (const [key, value] of Object.entries(newhire)) {
            if (newHireProgressIndicators.includes(key) && value === true) {
                setActiveStepsCount((activeStepsCount) => activeStepsCount + 1)
            }
        }

        // if password was displayed complete all steps (in case of follow up email step skip)
        if (newhire.isPasswordDisplayed) {
            setActiveStepsCount(steps.length)
        }

    }, [newhire])

    const steps = [
        'Send email',
        'Followup email',
        'Verify email',
        'Validate asset label',
        'Show passsword',
    ];

    return (
        <Box>
            <Stepper activeStep={activeStepsCount} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}

export default NewHiresProgress
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { AccountStatus } from ".."
import OktaLogo from "../../assets/logos/okta-logo.png"
import AzureLogo from "../../assets/logos/azure-logo.svg"
import MsOfficeLogo from "../../assets/logos/ms-office-logo.svg"
import { IAccountStatusDetails, INewHireDetails } from "../../interfaces"
import NewHiresProgress from "./NewHiresProgress"


const useStyles = makeStyles({
    cell: {
        padding: 0,
        width: '20%'
    },
    detailsCell: {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '35px',
        padding: 0,
        width: '12rem',
    },
    accountStatus: {
        height: '20px',
        width: '40px',
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
    },
    accountLogo: {
        width: '90%',
        height: '60%',
        alignSelf: 'center'
    }
})

interface IProps {
    newhire: INewHireDetails
    accountStatus: IAccountStatusDetails
}

const TableRowDetails = ({ accountStatus, newhire }: IProps) => {
    const classes = useStyles();

    return (
        <Table size="small" aria-label="purchases">
            <TableHead>
                <TableRow>
                    <TableCell align="center">Account status</TableCell>
                    <TableCell align="center">Progress status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell className={classes.cell} colSpan={1}>
                        <div className={classes.detailsCell}>
                            <AccountStatus isActive={accountStatus.oktaStatus} className={classes.accountStatus}>
                                <Tooltip title={accountStatus.oktaStatus ? 'Active' : 'Inactive'}>
                                    <img alt="okta-account-logo" src={OktaLogo} className={classes.accountLogo} />
                                </Tooltip>
                            </AccountStatus>
                            <AccountStatus isActive={accountStatus.adsStatus} className={classes.accountStatus}>
                                <Tooltip title={accountStatus.adsStatus ? 'Active' : 'Inactive'}>
                                    <img alt="ad-account-logo" src={AzureLogo} style={{ width: '100%', height: '100%' }} className={classes.accountLogo} />
                                </Tooltip>
                            </AccountStatus>
                            <AccountStatus isActive={accountStatus.msOfficeStatus} className={classes.accountStatus}>
                                <Tooltip title={accountStatus.msOfficeStatus ? 'Active' : 'Inactive'}>
                                    <img alt="ms-office-logo" src={MsOfficeLogo} style={{ width: '100%', height: '100%' }} className={classes.accountLogo} />
                                </Tooltip>
                            </AccountStatus>
                        </div>
                    </TableCell>
                    <TableCell className={classes.cell}>
                        <NewHiresProgress newhire={newhire} />
                    </TableCell>
                    <TableCell className={classes.cell}></TableCell>
                    <TableCell className={classes.cell}></TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default TableRowDetails
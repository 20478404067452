import {
    TableRow,
    TableCell,
    IconButton,
    Collapse,
    Box,
    Typography,
    Tooltip,
    Checkbox,
    CircularProgress,
} from "@material-ui/core";
import {
    KeyboardArrowUp,
    KeyboardArrowDown,
    CheckCircleOutline,
    HighlightOffOutlined,
    ErrorOutline
} from "@material-ui/icons"
import { TableRowDetails } from ".."
import { ROW_HEIGHT } from "./NewHiresTable";
import { useAppSelector } from "../../redux/hooks";
import EmailActionButton from "../EmailActionButton"
import { STATUS_BAD, STATUS_OK } from "../../styles/colors"
import { useState, Fragment, useEffect, ChangeEvent } from "react"
import { IAccountStatusDetails, INewHireDetails, INewhireShortDetails } from "../../interfaces"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

// interface of props of the component
interface IProps {
    row: INewHireDetails
    id: string
    rowIndex: number
    isItemSelected: boolean
    labelId: string
    handleSelectClick: (event: ChangeEvent<unknown>, newhire: INewhireShortDetails) => void
}

// interface of state of the component
interface State {
    accountDetails: IAccountStatusDetails
    allAccountStatus: boolean
    errorMessage: string
    isError: boolean
    isLoading: boolean
    openCollapse: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: `${ROW_HEIGHT}px`,
        },
        detailsCell: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            height: '30px',
        },
        accountLogo: {
            width: '100%',
            height: '100%'
        }
    }),
);

const getBackgroundColor = (index: number): string => {
    if (index % 2 === 0) {
        return "#EAF6FC"
    }
    return ""
}

const initAccountDetailsState: IAccountStatusDetails = {
    fmno: '111111',
    adsStatus: false,
    oktaStatus: false,
    msOfficeStatus: false,
}

// render icons based on a case
function renderAccountStatusIcons(isError: boolean, isAccountsActive: boolean): JSX.Element {
    // in case of error show error icon
    if (isError) {
        return <ErrorOutline style={{ color: STATUS_BAD }} />
    }

    // in case of inactive account show off icon
    if (!isAccountsActive) {
        return <HighlightOffOutlined style={{ color: STATUS_BAD }} />
    }

    // otherwise show status ok icon
    return <CheckCircleOutline style={{ color: STATUS_OK }} />
}


const Row = (props: IProps) => {
    const classes = useStyles();
    const { accounts } = useAppSelector(state => state)
    const [values, setValues] = useState<State>({
        accountDetails: initAccountDetailsState,
        allAccountStatus: false,
        isError: false,
        errorMessage: '',
        isLoading: false,
        openCollapse: false
    })
    const { row, rowIndex, isItemSelected, labelId, handleSelectClick } = props;

    /**
     * get account details from the accounts list
     * @param fmno fmno of the user
     */
    const getAccountStatus = (fmno: string): IAccountStatusDetails | undefined => {
        if (accounts.list?.length > 0) {
            return accounts.list.find(account => account.fmno === fmno)
        }

        return undefined
    }

    useEffect(() => {
        setValues({ ...values, isLoading: true })
        const account = getAccountStatus(row.fmno.toString())
        if (account) {
            setValues({
                ...values,
                isError: false,
                errorMessage: '',
                isLoading: false,
                accountDetails: account,
                allAccountStatus: account.adsStatus && account.msOfficeStatus && account.oktaStatus
            })
        } else {
            setValues({
                ...values,
                isLoading: false,
                isError: true,
                errorMessage: accounts.error?.message || 'account status not found'
            })
        }
    }, [accounts, props])

    return (
        <Fragment>
            <TableRow
                className={classes.root}
                style={{ backgroundColor: getBackgroundColor(rowIndex) }}
                aria-checked={isItemSelected}
                selected={isItemSelected}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={isItemSelected}
                        onChange={(event: ChangeEvent) => {
                            handleSelectClick(event, { id: row.id, fmno: row.fmno, email: row.email, officeCode: row.officeCode } as INewhireShortDetails)
                        }}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setValues({ ...values, openCollapse: !values.openCollapse })}>
                        {values.openCollapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell align="left">{row.fmno}</TableCell>
                <TableCell align="left">{row.firstName}</TableCell>
                <TableCell align="left">{row.lastName}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.officeCode}</TableCell>
                <TableCell align="center">
                    <Tooltip title={values.isError ? values.errorMessage : (values.allAccountStatus ? 'Active' : 'Inactive')}>
                        {values.isLoading ?
                            <CircularProgress size={25} />
                            :
                            renderAccountStatusIcons(values.isError, values.allAccountStatus)
                        }
                    </Tooltip>
                </TableCell>
                <TableCell align="center">
                    {/* <NewHiresProgress newhire={row} /> */}
                    <EmailActionButton newHire={row} />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={values.openCollapse} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Details
                            </Typography>
                            <TableRowDetails newhire={row} accountStatus={values.accountDetails} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}



export default Row
import { ADMIN_API_URL } from "../../config"
import { INewHireDetails, INewHiresList } from "../../interfaces"
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit"
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"

// Filters type
type Filters = {
  office: string
}

// Define a type for the slice state
interface INewHires {
  list: INewHiresList
  filteredList: INewHiresList
  filters: Filters
  loading: boolean
  error: Error | null
}

// Define the initial state using that type
const initialState: INewHires = {
  list: [],
  filteredList: [],
  filters: {
    office: "",
  },
  loading: false,
  error: null,
}

export const slice = createSlice({
  name: "newhires",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    getDataLoading(state) {
      state.loading = true
    },
    getNewhiresListSuccess(state, action: PayloadAction<INewHiresList>) {
      state.loading = false
      state.error = null
      state.list = action.payload
    },
    getNewhiresListError(state, action: PayloadAction<Error>) {
      state.loading = false
      state.list = []
      state.error = action.payload
    },
    getNewhireSuccess(state, action: PayloadAction<INewHireDetails>) {
      state.loading = false
      state.error = null
      state.list = [action.payload]
    },
    getNewhireError(state, action: PayloadAction<Error>) {
      state.loading = false
      state.list = []
      state.error = action.payload
    },
    updateIsEmailSent(
      state,
      action: PayloadAction<{ id: string; isEmailSent: boolean }>
    ) {
      state.list = state.list.map((n) => {
        if (n.id === action.payload.id) {
          n.isEmailSent = action.payload.isEmailSent
        }
        return n
      })
    },
    updateOfficeFilter(state, action: PayloadAction<{ office: string }>) {
      state.filters.office = action.payload.office
    },
    filterNewHires(state, action: PayloadAction<Filters>) {
      const { office } = action.payload
      state.filters = { office }
      if (office) {
        state.filteredList = state.list.filter((n) =>
          n.officeCode.toUpperCase().includes(office)
        )
      }
    },
    getNewhiresByBatchFmnosSuccess(
      state,
      action: PayloadAction<INewHiresList>
    ) {
      state.loading = false
      state.error = null
      state.list = action.payload
    },
  },
})

const { reducer, actions } = slice

const {
  getDataLoading,
  getNewhiresListSuccess,
  getNewhiresListError,
  getNewhireSuccess,
  getNewhireError,
  updateIsEmailSent,
  filterNewHires,
  updateOfficeFilter,
  getNewhiresByBatchFmnosSuccess,
} = actions

export const setOfficeCode = (office: string) => async (dispatch: Dispatch) => {
  dispatch(updateOfficeFilter({ office }))
}

export const filterListofNewHires =
  (filters: Filters) => async (dispath: Dispatch) => {
    dispath(filterNewHires(filters))
  }

/**
 * batch search by fmnos list
 * @param fmnos list of fmnos
 */
export const batchSearchFmnos =
  (accessToken: string | undefined, fmnos: number[]) =>
  async (dispatch: any) => {
    // if missing access token show error
    if (!accessToken) {
      dispatch(getNewhiresListError(new Error("missing required access token")))
    }

    const axiosConfig: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
    const url: string = `${ADMIN_API_URL}/newhires`

    // remove other filters
    dispatch(updateOfficeFilter({ office: "" }))

    try {
      dispatch(getDataLoading())
      const resp: AxiosResponse = await axios.get<INewHiresList>(
        url,
        axiosConfig
      )
      const newhiresList = resp.data

      // list to track found fmnos
      let foundFmnos: INewHireDetails[] = []

      // list to track not found fmnos
      let notFoundFmnos: number[] = []

      // loop over list of search fmnos
      fmnos.forEach((fmno) => {
        const foundFmno = newhiresList.find(
          (n: INewHireDetails) => n.fmno === fmno
        )
        // if fmno is found add it to the list
        if (foundFmno !== undefined) {
          foundFmnos.push(foundFmno)
        } else {
          // if fmno is not found
          notFoundFmnos.push(fmno)
        }
      })

      // display error if at least 1 not found fmno
      if (notFoundFmnos.length > 0) {
        dispatch(
          getNewhiresListError(new Error(`Sorry, we can't find ${notFoundFmnos}`))
        )
      }
      dispatch(getNewhiresByBatchFmnosSuccess(foundFmnos))
    } catch (err: any) {
      const errMsg = processAxiosError(err)
      dispatch(getNewhiresListError(errMsg))
    }
  }

export const fetchListOfNewHires =
  (accessToken: string | undefined) => async (dispatch: any) => {
    // if missing access token show error
    if (!accessToken) {
      dispatch(getNewhiresListError(new Error("missing required access token")))
    }

    const axiosConfig: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
    const url: string = `${ADMIN_API_URL}/newhires`
    // remove other filters
    dispatch(updateOfficeFilter({ office: "" }))

    try {
      dispatch(getDataLoading())
      const resp: AxiosResponse = await axios.get<INewHiresList>(
        url,
        axiosConfig
      )
      dispatch(getNewhiresListSuccess(resp.data))
    } catch (err: any) {
      const errMsg = processAxiosError(err)
      dispatch(getNewhiresListError(errMsg))
    }
  }

/**
 * fetch a newhire by email
 * @param accessToken okta JWT token
 * @param email user's email
 * @returns
 */
export const fetchNewhireByEmail =
  (accessToken: string | undefined, email: string) =>
  async (dispatch: Dispatch) => {
    dispatch(getDataLoading())

    // if missing access token show error
    if (!accessToken) {
      dispatch(getNewhireError(new Error("missing required access token")))
    }

    const axiosConfig: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
    const url: string = `${ADMIN_API_URL}/newhire?email=${email}`

    // remove other filters
    dispatch(updateOfficeFilter({ office: "" }))

    try {
      const resp: AxiosResponse = await axios.get<INewHireDetails>(
        url,
        axiosConfig
      )
      dispatch(getNewhireSuccess(resp.data))
    } catch (err: any) {
      const errMsg = processAxiosError(err)
      dispatch(getNewhireError(errMsg))
    }
  }

/**
 * fetch a newhire by fmno
 * @param accessToken okta JWT token
 * @param fmno user's fmno
 * @returns
 */
export const fetchNewhireByFmno =
  (accessToken: string | undefined, fmno: number) =>
  async (dispatch: Dispatch) => {
    dispatch(getDataLoading())

    // if missing access token show error
    if (!accessToken) {
      dispatch(getNewhireError(new Error("missing required access token")))
    }

    const axiosConfig: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
    const url: string = `${ADMIN_API_URL}/newhire?fmno=${fmno}`

    // remove other filters
    dispatch(updateOfficeFilter({ office: "" }))

    try {
      const resp: AxiosResponse = await axios.get<INewHireDetails>(
        url,
        axiosConfig
      )
      dispatch(getNewhireSuccess(resp.data))
    } catch (err: any) {
      const errMsg = processAxiosError(err)
      dispatch(getNewhireError(errMsg))
    }
  }

/**
 * update new-hire's progress status
 * @param id new-hire's unique id
 * @param isEmailSent new-hire's isEmailSent progress status
 * @returns
 */
export const updateNewHireProgress =
  (id: string, isEmailSent: boolean) => async (dispatch: any) => {
    dispatch(updateIsEmailSent({ id, isEmailSent }))
  }

const processAxiosError = (err: Error | AxiosError): Error => {
  if (!axios.isAxiosError(err)) {
    return err
  }

  const errData = err.response?.data
  if (!errData) {
    return err
  }

  if (errData["errors"] && errData["errors"].length > 0) {
    return new Error(errData["errors"][0])
  }

  if (errData["error"]) {
    return new Error(errData["error"])
  }

  return new Error("Unexpected error")
}

export default reducer

import { ChangeEvent, useEffect } from "react"
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { filterListofNewHires, setOfficeCode } from '../../redux/reducers/newhires';
import { McK_MID_GRAY } from "../../styles/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginBottom: theme.spacing(1),
            width: '100%',
            minWidth: 120,
        },
        textField: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        searchIcon: {
            padding: 10,
            color: McK_MID_GRAY
        }
    }),
);

const OfficeCodeFilter = () => {
    const { newhires } = useAppSelector(state => state)
    const dispatch = useAppDispatch()
    const classes = useStyles()
    const officeCode = newhires.filters.office

    // remove duplicate office codes
    const uniqueOfficeCodes = Array.from(new Set(newhires.list.map(n => n.officeCode)))

    useEffect(() => {
        dispatch(filterListofNewHires({ office: officeCode }))
    }, [newhires.filters.office])

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        dispatch(setOfficeCode(event.target.value as string))
    };

    return (
        <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
        >
            <InputLabel id="office-code-label">Office</InputLabel>
            <Select
                labelId="office-code-label"
                id="office-code"
                value={officeCode}
                onChange={handleChange}
                label="Office"
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {uniqueOfficeCodes.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default OfficeCodeFilter
import { useState, ChangeEvent, MouseEvent } from "react"
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { IconButton, TextField, InputAdornment } from "@material-ui/core"
import { useOktaAuth } from '@okta/okta-react';
import { useAppDispatch } from '../../redux/hooks';
import { batchSearchFmnos, fetchListOfNewHires, fetchNewhireByEmail, fetchNewhireByFmno } from '../../redux/reducers/newhires';
import { trackStackTrace } from "../../utils/logger";
import { Search } from "@material-ui/icons";
import { McK_MID_GRAY } from "../../styles/colors";
import { ActionAlert } from "../"

type Alert = {
    message: string
    open: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '350px',
            height: '55px',
            marginRight: '10px',
        },
        textField: {
            flex: 1,
        },
        searchIcon: {
            padding: 10,
            color: McK_MID_GRAY
        }
    }),
);

const EmailAndFmnoSearch = () => {
    const dispatch = useAppDispatch()
    const [searchVal, setSearchVal] = useState("")
    const { authState, oktaAuth } = useOktaAuth();
    const classes = useStyles()
    const [alert, setAlert] = useState<Alert>({ open: false, message: '' })

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchVal(event.target.value)
    }

    function handleAlertClose(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ open: false, message: '' })
    }

    const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        oktaAuth.getUser()
            .then((info: any) => {
                trackStackTrace("search for list of newhires by email or fmno", { adminEmail: info?.email })
            })

        if (searchVal === "") {
            // fetch all newhires
            dispatch(fetchListOfNewHires(authState?.accessToken?.accessToken))
        } else if (!isNaN(Number(searchVal))) {
            dispatch(fetchNewhireByFmno(authState?.accessToken?.accessToken, Number(searchVal)))
        } else if (searchVal.includes("@")) {
            // fetch by email
            dispatch(fetchNewhireByEmail(authState?.accessToken?.accessToken, searchVal))
        } else if (searchVal.includes(",")) {
            // batch fetch
            const fmnos = searchVal.trim().replaceAll(" ", "").split(",").map(fmno => {
                const number = Number(fmno)
                if (isNaN(number)) {
                    setAlert({
                        open: true,
                        message: `Invalid fmno: ${fmno}, fmno must be a number`
                    })
                    return 0
                }
                return number
            })
            dispatch(batchSearchFmnos(authState?.accessToken?.accessToken, fmnos))
        } else {
            setAlert({
                open: true,
                message: "invalid search value, it must be a valid email address, fmno or comma separated list of fmnos"
            })
        }
    }

    return (
        <form
            className={classes.root}
            noValidate
            autoComplete="off"
        >
            <TextField
                id="search-by-email-or-fmno"
                label="Search by fmno or email"
                variant="outlined"
                className={classes.textField}
                onChange={handleChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                type="submit"
                                className={classes.searchIcon}
                                aria-label="search button"
                                onClick={handleSubmit}
                            >
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <ActionAlert
                isError={true}
                isOpen={alert.open}
                message={alert.message}
                onClose={handleAlertClose}
            />
        </form>
    )
}

export default EmailAndFmnoSearch
import { INewHireDetails } from "../interfaces";

export type Order = 'asc' | 'desc'

/**
 * compare objects in ascending order
 * @param a first object to compare
 * @param b second object to compare
 * @param orderBy key name to order by
 * @returns {number} order number
 */
export function ascComparator(
    a: INewHireDetails,
    b: INewHireDetails,
    orderBy: keyof INewHireDetails
): number {
    if (a[orderBy] > b[orderBy]) {
        return 1
    }
    if (a[orderBy] < b[orderBy]) {
        return -1
    }
    return 0
}

/**
 * compare objects in descending order
 * @param a first object to compare
 * @param b second object to compare
 * @param orderBy key name to order by
 * @returns order number
 */
export function descComparator(
    a: INewHireDetails,
    b: INewHireDetails,
    orderBy: keyof INewHireDetails
): number {
    if (a[orderBy] > b[orderBy]) {
        return -1
    }
    if (a[orderBy] < b[orderBy]) {
        return 1
    }
    return 0
}

/**
 * returns valid comparator based on order type and order by
 * @param order order type asc or desc
 * @param orderBy key name to order by
 * @returns comparator function
 */
export function getComparator(order: Order, orderBy: keyof INewHireDetails):
    (a: INewHireDetails, b: INewHireDetails) => number {
    return (a, b) =>
        order === 'desc'
            ? descComparator(a, b, orderBy)
            : ascComparator(a, b, orderBy)
}

/**
 * sorts new-hires list with provided comparator
 * @param array list of new-hires
 * @param comparator comparator label by which we sort
 * @returns 
 */
export function stableSort(
    array: INewHireDetails[],
    comparator: (a: INewHireDetails, b: INewHireDetails) => number
) {
    const sortedArr: INewHireDetails[] = array.map(el => el)
        .sort((a, b) => comparator(a, b))
    return sortedArr
}

export function stableSortDef(
    array: INewHireDetails[],
    comparator: (a: INewHireDetails, b: INewHireDetails) => number
): INewHireDetails[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [INewHireDetails, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
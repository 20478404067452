import {
    Typography,
    Toolbar,
    createStyles,
    makeStyles,
    Theme,
    lighten,
} from '@material-ui/core'
import clsx from 'clsx'
import { BatchEmailActionButton } from '..';
import { INewhireShortDetails } from '../../interfaces';

interface ISelectedNewHiresToolbar {
    numSelected: number;
    selectedList: INewhireShortDetails[]
}

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
    }),
);

const SelectedNewHiresToolbar = (props: ISelectedNewHiresToolbar) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Users
                </Typography>
            )}
            {numSelected > 0 && <BatchEmailActionButton newhires={props.selectedList}/>}
        </Toolbar>
    );
};

export default SelectedNewHiresToolbar
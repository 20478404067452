import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IAccountStatusDetails } from "../../interfaces"
import { ACCOUNT_STATUS_API_URL } from "../../config"
import { trackException, trackStackTrace } from "../../utils/logger"

interface IAccountsStatus {
  list: IAccountStatusDetails[]
  error: AxiosError | Error | null
}

const initialState: IAccountsStatus = {
  list: [],
  error: null,
}

const adminSlice = createSlice({
  name: "accountsStatus",
  initialState,
  reducers: {
    getAccountsSuccess(state, action: PayloadAction<IAccountStatusDetails[]>) {
      state.list = action.payload
    },
    getAccountsError(state, action: PayloadAction<AxiosError | Error>) {
      state.error = action.payload
    },
  },
})

const { reducer, actions } = adminSlice
// export actions
export const { getAccountsSuccess, getAccountsError } = actions

/**
 * get new hires' accounts statuses
 * @param accessToken Okta access token
 * @returns
 */
export const fetchListOfNewHireAccounts =
  (accessToken: string | undefined) => async (dispatch: any) => {
    // if missing access token show error
    if (!accessToken) {
      dispatch(getAccountsError(new Error("missing required access token")))
    }

    const url: string = `${ACCOUNT_STATUS_API_URL}/newhires/account-status`
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }

    try {
      trackStackTrace("fetch list of accounts")
      const resp: AxiosResponse = await axios.get<IAccountStatusDetails[]>(
        url,
        axiosConfig
      )
      trackStackTrace("fetch list of accounts finished")
      dispatch(getAccountsSuccess(resp.data))
    } catch (err: any) {
      trackException(new Error(err.response?.data.message || err.message))
      dispatch(getAccountsError(err))
    }
  }

// export reducer
export default reducer

import {
    Theme,
    Button,
    Tooltip,
    Backdrop,
    makeStyles,
    CircularProgress,
} from "@material-ui/core";
import { useState } from "react"
import { Email } from "@material-ui/icons"
import { getOktaToken } from "../../utils";
import { ADMIN_API_URL } from "../../config";
import BatchEmailDialog from "./BatchEmailDialog";
import { useAppDispatch } from "../../redux/hooks";
import { McK_ELECTRIC_BLUE } from "../../styles/colors";
import { INewhireShortDetails } from "../../interfaces";
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'

interface AxiosReqBody {
    newhires: INewhireShortDetails[]
}

interface IProps {
    newhires: INewhireShortDetails[]
}

export type FailedEmail = {
    email: string
    error: string
}

interface State {
    message: string
    backdrop: boolean
    dialog: boolean
    dialogMessage: string
    failedList: FailedEmail[]
    isError: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    button: {
        width: '150px',
    },
    primaryBtn: {
        backgroundColor: McK_ELECTRIC_BLUE,
    },
}))

const BatchEmailActionButton = ({ newhires }: IProps) => {
    const dispatch = useAppDispatch()
    const classes = useStyles()
    const [values, setValues] = useState<State>({
        message: '',
        backdrop: false,
        dialog: false,
        dialogMessage: '',
        failedList: [],
        isError: false,
    })

    const sendEmail = async () => {
        // show backdrop
        setValues({ ...values, backdrop: true })

        const tokenObj = getOktaToken()
       
        const token = tokenObj['accessToken'].accessToken;

        // if missing access token show error
        if (!token) {
            alert(new Error('missing required access token', token))
        }

        const axiosConfig: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }

        const reqBody: AxiosReqBody = {
            newhires: newhires
        }

        try {
            let apiURL: string = `${ADMIN_API_URL}/newhires/email`
            const resp: AxiosResponse = await axios.put(apiURL, reqBody, axiosConfig)
            setValues({ ...values, backdrop: false, dialog: true, dialogMessage: resp.data?.message })
        } catch (err) {
            if (axios.isAxiosError(err)) {
                const errors = err.response?.data["errors"]
                // extract email address
                const failedList: FailedEmail[] = errors.map((e: any) =>
                    ({ email: e.message.split("'")[1], error: e.details } as FailedEmail)
                )
                // show dialog with error
                setValues({
                    ...values,
                    dialog: true,
                    dialogMessage: "failed to send some emails",
                    failedList: failedList
                })
            } else {
                alert(err)
                setValues({ ...values, backdrop: false, dialog: true, dialogMessage: (err as Error)?.message})
            }
        }
    }

    const handleDialogClose = () => {
        setValues({ ...values, dialog: false })
    }


    return (
        <Tooltip title="Send email to all selected">
            <>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={`${classes.button} ${classes.primaryBtn}`}
                    endIcon={<Email>send</Email>}
                    onClick={sendEmail}
                >
                    Send batch
                </Button>
                <Backdrop className={classes.backdrop} open={values.backdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <BatchEmailDialog
                    open={values.dialog}
                    message={values.dialogMessage}
                    failedList={values.failedList} handleClose={handleDialogClose}
                />
            </>
        </Tooltip>
    )
}

export default BatchEmailActionButton